// bootstrap
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/toast';

import 'select2';

import $ from 'jquery';
require('jquery-ui/ui/widgets/slider');

// expose jQuery on the window
window.$ = $;
window.jQuery = $;

require('./jquery-ui-touch-punch');
